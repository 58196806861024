// TODO: Need to show error if checkin fails for punch card (in the event something changes on server)

import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import {
  checkInMembers,
  checkOutMembers,
  getMemberships,
  IServerMemberCheckIn,
  IServerMemberCheckOut,
} from "./server";
import {
  DefaultButton,
  Link,
  Panel,
  PanelType,
  Stack,
  Text
} from "@fluentui/react";
import { getAttendance, BASE_URL } from "./server";
import { IMember, IMembershipTracker } from "./Membership";
import { IServerAttendanceEntry } from "./AttendanceEntry";
import { getLastRefresh, getMotd, setLastRefresh } from "./storage";
import { MessageOfTheDayEditor } from "./MessageOfTheDayEditor";
import { MemberNotFoundReport } from "./MemberNotFoundReport";
import { AuthSetup } from "./AuthSetup";
import { MemberList } from "./MemberList";
import { ErrorModal } from "./ErrorModal";

initializeIcons();

// This is the context used to have a global membership list used by the entire app. It's shared
// from the root of the list and can be modified anywhere else.
type MembershipListContextType = {
  members: IMembershipTracker[];
  setMembers: React.Dispatch<React.SetStateAction<IMembershipTracker[]>>;
};

export const MembershipList = createContext<MembershipListContextType>({
  members: [],
  setMembers: () => {},
});

type ErrorStateType = {
  isOpen: boolean;
  message: string;
  title: string;
};

// App function.
function App() {
  const [showLaneRes, setShowLaneRes] = useState(false);
  const [iframeSrc, setIFrameSrc] = useState("");
  const [members, setMembers] = useState<IMembershipTracker[]>([]);
  const [errorState, setErrorState] = useState<ErrorStateType>({
    isOpen: false,
    title: "",
    message: "",
  });
  const [checkedInCount, setCheckedInCount] = useState(0);

  console.log(JSON.stringify(members));
  const checkInUser = (users: IMember[]) => {
    console.log("Checkin: " + users);
    const usersToCheckIn: IServerMemberCheckIn[] = [];
    users.forEach((user) => {
      user.isCheckedIn = true;
      user.checkInTime = new Date();
      usersToCheckIn.push({
        memberName: user.name,
        memberId: user.membershipId,
        isGuest: user.isGuest || user.isPunchCard || false,
        timeIn: user.checkInTime,
      });
    });
    checkInMembers(usersToCheckIn);
    const updatedUsers = [...members];
    setMembers(updatedUsers);
    setCheckedInCount(checkedInCount + users.length);
  };

  const checkOutUser = (users: IMember[]) => {
    console.log("Checkout: " + users);
    const usersToCheckOut: IServerMemberCheckOut[] = [];
    users.forEach((user) => {
      user.isCheckedIn = false;
      user.checkInTime = undefined;
      usersToCheckOut.push({
        memberName: user.name,
        memberId: user.membershipId,
      });
    });
    checkOutMembers(usersToCheckOut);
    const updatedUsers = [...members];
    setMembers(updatedUsers);
    setCheckedInCount(checkedInCount - users.length);
  };

  useEffect(() => {
    console.log("Fetching...");
    let attendance: IServerAttendanceEntry[] = [];

    getAttendance()
      .then((result) => {
        attendance = result;
      })
      .then(() => {
        return getMemberships();
      })
      .then((memberships) => {
        let checkedIn = 0;
        // Update attendance from server.
        attendance.forEach((entry) => {          
          if (!entry.Exit) {
            checkedIn++;
            for (var i = 0; i < memberships.length; i++) {
              const membership = memberships[i];
              if (membership.membershipId === entry.MembershipId) {
                let found = false;
                for (var j = 0; j < membership.members.length; j++) {
                  if (membership.members[j].name === entry.MemberName) {
                    console.log(typeof entry.Entry);
                    membership.members[j].checkInTime = new Date(entry.Entry);
                    membership.members[j].isCheckedIn = true;
                    found = true;
                    break;
                  }
                }
                // Add 'fake guests' to the membership for login/out management
                if (!found) {
                  membership.members.push({
                    name: entry.MemberName,
                    checkInTime: new Date(entry.Entry),
                    isCheckedIn: true,
                    isGuest: true,
                    membershipId: membership.membershipId,
                    isPunchCard: false,
                  });
                }
                break;
              }
            }
          }
          console.log("2");
        });
        setMembers(memberships);
        setCheckedInCount(checkedIn);
      })
      .catch((e) => {
        setErrorState({
          isOpen: true,
          message: "Cannot talk to server.",
          title: "Check Connectivity",
        });
      });
  }, []);

  useEffect(() => {
    const reload = () => {
      // const iframe = document.getElementsByTagName("iframe") as any[];
      // const newUrl = iframe[0].src + "&uid="+Math.random();
      // console.log("Refresh:" + newUrl) // workingon this
      // iframe[0].src = newUrl;
      //iframe.reload;
      var now = new Date()
      var lastReload = new Date(getLastRefresh()!);
      setLastRefresh();
      if (now.getDate() !== lastReload.getDate() && now.getHours() >= 1) {
        window.location.reload();
      }
      
    };
    // Every hour 60 mins * 60 seconds * 1000 ms
    const timerId = setInterval(reload, 60*60*1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);
  return (
    <div className="App">
      <div style={{ position: "absolute", left: "80%" }}>
        <DefaultButton
          style={{color: "#030099", borderRadius: 12}}
          onClick={() => {
            // setIFrameSrc(
            //   "http://localhost:8080/?checkinkiosk=46E8A938-46C2-4968-BA87-E83482F29B04"
            // );
            setIFrameSrc(
              "https://soclanerental.azurewebsites.net/?checkinkiosk=46E8A938-46C2-4968-BA87-E83482F29B04"
            );
            setShowLaneRes(true);
          }}
        >
          Lane Reservations
        </DefaultButton>
      </div>
      <div style={{ position: "absolute", left: "5%" }}>
            <Text variant="medium">Checked In: {checkedInCount}</Text>
      </div>
      <Stack>
        <Stack.Item>
          {process.env.NODE_ENV === "development" && (
            <div>
            <div>Communicating with: {BASE_URL}</div>
            <div>Last Update: {getLastRefresh()}</div>
            </div>
          )}
          <div className="Banner">
            <img src="title.png" alt="soc pool"></img>
          </div>
          <div className="motd">
            <Text variant="large">{getMotd() || "Welcome to the pool!"}</Text>
          </div>
        </Stack.Item>
        <Stack.Item grow>
          {window.location.search === "?motd" && (
            <MessageOfTheDayEditor></MessageOfTheDayEditor>
          )}
          {window.location.search === "?membernotfound" && (
            <MemberNotFoundReport />
          )}
          {window.location.search === "?setup" && <AuthSetup></AuthSetup>}
          {/* <PavillionCheckIn></PavillionCheckIn> */}
          {window.location.pathname === "/" &&
            window.location.search.length === 0 && (
              <MembershipList.Provider value={{ members, setMembers }}>
                <MemberList
                  onCheckInUser={checkInUser}
                  onCheckOutUser={checkOutUser}
                ></MemberList>
              </MembershipList.Provider>
              //   <MemberCheckIn
              //   members={users}
              //   onCheckInUser={checkInUser}
              //   onCheckOutUser={checkOutUser}
              // ></MemberCheckIn>
            )}
        </Stack.Item>
        {false && <Stack.Item>
          <p>
            <Link href="https://socpool.square.site/">
              SOC Pool Square Store
            </Link>
          </p>
        </Stack.Item>}
      </Stack>
      <Panel
        headerText="Lane Reservations"
        type={PanelType.medium}
        isOpen={showLaneRes}
        isLightDismiss
        onDismiss={() => {
          setIFrameSrc("about:blank");
          setShowLaneRes(!showLaneRes);
        }}
      >
        <Stack>
          <Stack.Item>
            <iframe
              id={"lanes"}
              style={{
                width: "90%",
                height: "95%",
                border: "0px",
                position: "absolute"
              }}
              title="reservation system"
              src={iframeSrc}
            ></iframe>
          </Stack.Item>
        </Stack>
      </Panel>
      <ErrorModal
        isOpen={errorState.isOpen}
        onDismiss={() =>
          setErrorState({ isOpen: false, message: "", title: "" })
        }
        title={errorState.title}
        message={errorState.message}
      ></ErrorModal>
    </div>
  );
}

export default App;
