import { IServerAttendanceEntry } from "./AttendanceEntry";
import { fromServerDataType, IMembershipTracker, IServerMembership } from "./Membership";
import { getAuth } from "./storage";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOCAL_SERVER_URL = "http://localhost:7071/api";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SERVER_URL = "https://soclanesfunctions.azurewebsites.net/api";

export const BASE_URL = SERVER_URL; //  OCAL_SERVER_URL; //  LOCAL_SERVER_URL; //
const ERROR_PAGE_URL = "https://socpool.com";


console.log("BASE_URL"+BASE_URL)
// TODO: make this a class?
// TODO: api key is a member and a caller is a private method

export const getMemberships = (): Promise<IMembershipTracker[]> => {
  // Make call to server with API key
  var memberships = fetch(`${BASE_URL}/memberships`, {
    headers: {
      "x-functions-key": getAuth(),
    },
  })
    .then((res) => {
      console.log("getMembershps: " + res.ok);
      if (res.ok) return res.json();
      if (res.status > 400) {
        return [];
      }
    })
    .then((members: IServerMembership[]) => {
      return fromServerDataType(members);
    });

  return memberships;
};

export const getAttendance = (): Promise<IServerAttendanceEntry[]> => {
  // Make call to server with API key
  var currentAttendance = fetch(`${BASE_URL}/attendance`, {
    headers: {
      "x-functions-key": getAuth(),
    },
  })
    .then((res) => {
      console.log("attendance: " + res.ok);
      if (res.ok) return res.json();
      if (res.status > 400) {
        // window.location.href = ERROR_PAGE_URL;
        return [];
      }
    })
    .then((attendance: IServerAttendanceEntry[]) => {
      return attendance;
    });

  return currentAttendance;
};

export interface IServerMemberCheckIn {
  memberId: string;
  memberName: string;
  timeIn: Date;
  isGuest: boolean;
}

interface IServerMemberCheckInInfo{
  Name: string;
  IsGuest: boolean;
};

interface IServerCheckInRequest {
  MembershipId: string;
  Members: IServerMemberCheckInInfo[];
};

export interface IServerMemberCheckOut {
  memberId: string;
  memberName: string;
}

export const  checkInMembers = async (
  members: IServerMemberCheckIn[]
) => {
  var requestBody: IServerCheckInRequest = {
    MembershipId: members[0].memberId,
    Members: members.map(m => ({
      Name: m.memberName,
      IsGuest: m.isGuest
    }))
  };
    return fetch(
      `${BASE_URL}/checkin`,
      {
        headers: {
          "x-functions-key": getAuth(),
        },
        method: "POST",
        body: JSON.stringify(requestBody)
      }
    )
      .then((res) => {
        console.log("checkin: " + res.ok);
        if (res.ok) {
          console.log("Checkin successful!");
          return res.json();
        }
        console.log("Checkin unsuccessful!");
        if (res.status === 400) {
          return res.json();
        }
        if (res.status > 400) {
          window.location.href = ERROR_PAGE_URL;
        }
      });
};

interface IServerCheckOutRequest {
  MembershipId: string;
  MemberNames: string[];
};

export const checkOutMembers = (
  members: IServerMemberCheckOut[]
) => {
  var requestBody: IServerCheckOutRequest = {
    MembershipId: members[0].memberId,
    MemberNames: members.map(m => m.memberName)
    };

    return fetch(
      `${BASE_URL}/checkout`,
      {
        headers: {
          "x-functions-key": getAuth(),
        },
        method: "POST",
        body: JSON.stringify(requestBody)
      }
    )
      .then((res) => {
        console.log("checkout: " + res.ok);
        if (res.ok) {
          console.log("checkout successful!");
          return res.json();
        }
        console.log("checkout unsuccessful!");
        if (res.status > 400) {
          window.location.href = ERROR_PAGE_URL;
        }
      });
};

interface IMembershipIssueRequest {
  Name: string;
  Email: string;
  PhoneNumber: string;
  Comments: string;
};

export const sendMemberIssue = (
  requestBody: IMembershipIssueRequest
) => {
    return fetch(
      `${BASE_URL}/memberIssue`,
      {
        headers: {
          "x-functions-key": getAuth(),
        },
        method: "POST",
        body: JSON.stringify(requestBody)
      }
    )
      .then((res) => {
        console.log("memberIssue: " + res.ok);
        if (res.ok) {
          console.log("memberIssue successful!");
          return {};
        }
        console.log("memberIssue unsuccessful!");
        if (res.status > 400) {
          window.location.href = ERROR_PAGE_URL;
        }
      });
};

