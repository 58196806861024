export const setMotd = (motd: string) => {
  localStorage.setItem("motd", motd);
  localStorage.setItem("motd-date", new Date().toLocaleString());
};

export const getMotd = (): string => {
  var lastUpdate: string | null = localStorage.getItem("motd-date");
  if (lastUpdate && new Date(lastUpdate).getDate() === new Date().getDate())
    return localStorage.getItem("motd") || "";
  return "";
};


let key: string | undefined = undefined;
export const setAuth = (authKey: string) => {
    key = authKey;
    localStorage.setItem("apiKey", authKey);
}

export const getAuth = (): string => {
    if (key) {
        console.log("returning cached key: "+key)
        return key;
    }
    key = localStorage.getItem("apiKey") || undefined;
    console.log("Retreived key: "+key)
    return key || "";
}

export const getLastRefresh = () => {
  return localStorage.getItem("lastRefresh");
}

export const setLastRefresh = () => {
  localStorage.setItem("lastRefresh", new Date().toUTCString());
}