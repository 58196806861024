import {
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useState } from "react";
import { sendMemberIssue } from "./server";

const sendEmailReport = async (name: string, email: string, phone: string, comments: string) => {
  await sendMemberIssue( {
    Name: name,
    Email: email,
    PhoneNumber: phone,
    Comments: comments
  });
  console.log("Setting");
  window.location.href = "/";
};

export const MemberNotFoundReport = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  return (
    <Stack tokens={{ childrenGap: 15, padding: 135 }}>
      <Text variant="large">Problem with membership?</Text>
      <TextField label="Name" required autoComplete="off" onChange={(evt, newValue) => setName(newValue || "")}></TextField>
      <TextField label="Email" required autoComplete="off" onChange={(evt, newValue) => setEmail(newValue || "")}></TextField>
      <TextField label="Phone Number (optional)" autoComplete="off" onChange={(evt, newValue) => setPhone(newValue || "")}></TextField>
      <TextField
        label="Comments"
        multiline
        rows={6}
        autoComplete="off"
        onChange={(evt, newValue) => setComments(newValue || "")}
      ></TextField>
      <PrimaryButton
        onClick={() => sendEmailReport(name, email, phone, comments)}
      >
        Send
      </PrimaryButton>
    </Stack>
  );
};
