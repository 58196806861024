import { TextField, Stack, PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import { setMotd } from "./storage";

export const MessageOfTheDayEditor = () => {
  const [message, setMessage] = useState<string>("");
  return (
    <Stack tokens= {{ childrenGap: 15, padding: 135}}>
      <TextField
        label="Enter message of the day:"
        multiline
        rows={4}
        defaultValue={message}
        onChange={(evt, newValue) => setMessage(newValue || "")}
      >
        {message}
      </TextField>
      <PrimaryButton
        onClick={() => {
          setMotd(message);
          window.location.href = "/";
        }}
      >
        Ok
      </PrimaryButton>
    </Stack>
  );
};
