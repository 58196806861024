import { PrimaryButton, Stack, TextField } from "@fluentui/react"
import { useState } from "react";
import { setAuth } from "./storage";

export const AuthSetup = () => {
    const [key, setKey] = useState<string>("");

    return (
        <Stack tokens={{ childrenGap: 15, padding: 135 }}>
            <TextField label="Enter API key" onChange={(evt, nv) => setKey(nv || "")}></TextField>
            <PrimaryButton onClick={() => setAuth(key)}>Setup</PrimaryButton>
        </Stack>
    )
}