//////////////////////////////////////////
// Interfaces matching server

export interface IServerMembership {
  Id: string;
  MembershipYear: number;
  MembershipType: string;
  BillingEmail: string;
  SquarePaymentId: string;
  SquareOrderId: string;
  Address: string;
  PhoneNumber: string;
  ContactEmails: string[];
  AdditionalNames: string[];
  Referred: string;
  FreeGuestPassCount: number;
  PaidGuestPassCount: number;
  Range: string;
}

///////////////////////////////////////////
// Client data types
// export interface IMembership {
//   membershipId: string;
//   type: string;
//   members: string[];
//   guestPasses: number;
// }

export interface IMember {
  name: string;
  isCheckedIn: boolean;
  checkInTime?: Date;
  membershipId: string;
  isGuest?: boolean;
  isPunchCard: boolean;
}

export interface IMembershipTracker {
  membershipId: string;
  name: string;
  members: IMember[];
  guestPasses: number;
}

export const fromServerDataType = (
  membershipList: IServerMembership[]
): IMembershipTracker[] => {
  return membershipList.map((m) => ({
    name: m["MembershipType"],
    members: m["AdditionalNames"].map(name => ({
        membershipId: m["Id"],
        name: name,
        isCheckedIn: false,
        isPunchCard: m["MembershipType"].includes("Punch Card")
    })),
    guestPasses: m["FreeGuestPassCount"] + m["PaidGuestPassCount"],
    membershipId: m["Id"],
  }));
};
