import {
  IconButton,
  IIconProps,
  Modal,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";

interface IProps {
  isOpen: boolean;
  title: string;
  message: string;
  onDismiss: () => void;
}

const cancelIcon: IIconProps = { iconName: "Cancel" };

export const ErrorModal = ({ isOpen, title, message, onDismiss }: IProps) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      <Stack tokens={{ childrenGap: 15 }}>
        <Stack horizontal verticalAlign="center">
          <Stack.Item grow={1}>
            <Text variant="large" style={{ margin: 8 }}>
              {title || "Error!"}
            </Text>
          </Stack.Item>
          <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </Stack>
        <Text variant="large" style={{ margin: 8 }}>
          {message}
        </Text>
        <PrimaryButton
          onClick={() => {
            // update chekcin/checkout
            onDismiss();
          }}
        >
          Ok
        </PrimaryButton>
      </Stack>
    </Modal>
  );
};
