import {
  IconButton,
  IIconProps,
  Modal,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { MembershipList } from "./App";
import MemberCheckIn2 from "./MemberCheckIn2";
import { IMember, IMembershipTracker } from "./Membership";

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  membership: IMembershipTracker | null;
  onCheckInUser: (u: IMember[]) => void;
  onCheckOutUser: (u: IMember[]) => void;
}

const cancelIcon: IIconProps = { iconName: "Cancel" };

const is_punchcard = (mb: IMembershipTracker) => {
  return mb.name === "10 Visit Punch Card"; // || mb.members[0].isPunchCard?
};

export const CheckinModal = ({
  isOpen,
  onDismiss,
  membership,
  onCheckInUser,
  onCheckOutUser,
}: IProps) => {
  const [usersToCheckIn, setUsersToCheckIn] = useState<Record<string, IMember>>(
    {}
  );
  const [usersToCheckOut, setUsersToCheckOut] = useState<
    Record<string, IMember>
  >({});
  const [guestCount, setGuestCount] = useState<number>(0);
  const { members, setMembers } = useContext(MembershipList);

  const onQueueCheckInUser = (m: IMember[], flag: boolean) => {
    console.log(JSON.stringify(usersToCheckIn));
    const newUsersToCheckIn = { ...usersToCheckIn };
    m.forEach((member) => {
      console.log(`${member.name} = ${flag}`);

      if (flag) {
        if (!member.isCheckedIn) {
          newUsersToCheckIn[member.name] = member;
        }
      } else {
        delete newUsersToCheckIn[member.name];
      }
    });
    //console.log(JSON.stringify(newUsersToCheckIn));
    setUsersToCheckIn(newUsersToCheckIn);
  };

  const onQueueCheckOutUser = (m: IMember[], flag: boolean) => {
    const newUsersToCheckOut = { ...usersToCheckOut };
    m.forEach((member) => {
      if (flag) {
        if (member.isCheckedIn) {
          newUsersToCheckOut[member.name] = member;
        }
      } else {
        delete newUsersToCheckOut[member.name];
      }
    });
    setUsersToCheckOut(newUsersToCheckOut);
  };

  const onAddGuest = (m: IMember) => {
    if (
      is_punchcard(membership!) &&
      membership!.guestPasses < guestCount + 1 + 1
    ) {
      // TODO: error!
      console.log("Error with punch card limit.");
      return;
    }

    onQueueCheckInUser([m], true);
    setGuestCount(guestCount + 1);

    console.log("Adding guest");
    // Add the user to the membership
    membership?.members.push(m);
    setMembers([...members]);
  };

  const onRemoveGuest = (m: IMember) => {
    onQueueCheckInUser([m], false);
    setGuestCount(Math.max(0, guestCount - 1));

    // Add the user to the membership
    membership!.members = membership!.members.filter((x) => x.name !== m.name);
    setMembers([...members]);
  };

  // let guestCount = 0;
  // membership?.members.forEach(element => {
  //     if (element.isGuest) {
  //         currentGuestCount += 1;
  //     }
  // });

  const callServer = () => {
    // Check-in:

    // Get new guest count
    let guestPassesUsed = guestCount;
    if (is_punchcard(membership!)) {
      guestPassesUsed += 1;
    }

    // Check-out:
    console.log(JSON.stringify(usersToCheckOut));
    const usersToCheckOutArr = Object.values(usersToCheckOut);
    if (usersToCheckOutArr.length > 0) {
      onCheckOutUser(usersToCheckOutArr);
    }

    // Check-in:
    console.log(JSON.stringify(usersToCheckIn));
    const usersToCheckInArr = Object.values(usersToCheckIn);
    if (usersToCheckInArr.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = onCheckInUser(usersToCheckInArr);
      // Verify response isn't an error for 10-punch
    }

    // Update hte membership guestpasses
    membership!.guestPasses -= guestPassesUsed;
    setMembers([...members]);
    setUsersToCheckIn({});
    setUsersToCheckOut({});
  };

  useEffect(() => {
    console.log("Users to checkin:");
    Object.keys(usersToCheckIn).forEach((key) => {
      console.log(" -> " + usersToCheckIn[key].name);
    });
    console.log("Users to checkout:");
    Object.keys(usersToCheckOut).forEach((key) => {
      console.log(" -> " + usersToCheckOut[key].name);
    });
  }, [usersToCheckOut, usersToCheckIn]);

  // Reset guest count each time we open/dismiss the dialog
  useEffect(() => {
    let count = 0;
    if (membership) {
      membership!.members.forEach((m) => {
        if (m.isGuest && !m.isCheckedIn) {
          count++;
        }
      });
      setGuestCount(count);
    }
  }, [isOpen, membership]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={false}
      allowTouchBodyScroll={true}
    >
      <div data-is-scrollable="true">
        <Stack tokens={{ childrenGap: 15 }}>
          <Stack
            horizontal
            verticalAlign="center"
            style={{ background: "#030099" }}
          >
            <Stack.Item grow={1}>
              <Text variant="large" style={{ margin: 8, color: "White" }}>
                {membership?.name}
              </Text>
            </Stack.Item>
            <IconButton
              style={{ color: "white" }}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          </Stack>
          {(membership?.guestPasses || 0) < 0 && (
            <Text style={{ margin: 8, color: "red" }}>
              Member is negative on guest passes.
            </Text>
          )}
          {membership?.guestPasses === 0 &&
            membership?.members.some((m) => m.isPunchCard) && (
              <Text style={{ margin: 28, color: "Maroon" }}>
                {membership?.members[0].name} has no punches left.
              </Text>
            )}
          {((membership?.guestPasses || 0) > 0 ||
            membership?.members.every((m) => !m.isPunchCard)) && (
            <MemberCheckIn2
              members={[membership!]}
              onQueueCheckInUser={onQueueCheckInUser}
              onQueueCheckOutUser={onQueueCheckOutUser}
              onAddGuest={onAddGuest}
              onRemoveGuest={onRemoveGuest}
            ></MemberCheckIn2>
          )}
          <PrimaryButton
            style={{
              background:
                Object.keys(usersToCheckIn).length === 0 &&
                Object.keys(usersToCheckOut).length === 0
                  ? "#F0F0F0"
                  : "#030099",
              borderRadius: 12,
              margin: 10,
            }}
            // Must have atleast one member with guest to check in, but checkout is ok without member.
            disabled={
              Object.keys(usersToCheckIn).length === 0 &&
              Object.keys(usersToCheckOut).length === 0
            }
            onClick={() => {
              callServer();
              onDismiss();
            }}
          >
            Submit
          </PrimaryButton>
          {/* Guests: {guestCount} */}
        </Stack>
      </div>
    </Modal>
  );
};
