import { Icon, Link, List, SearchBox, Stack, Text } from "@fluentui/react";
import { ReactElement, useContext, useEffect, useState } from "react";
import { MembershipList } from "./App";
import { CheckinModal } from "./CheckinModal";
import { IMember, IMembershipTracker } from "./Membership";

const rankList = (filterText: string, membership: IMembershipTracker) => {
  // Ranking algo
  // Full last name match: -100
  // Full first name match: -50
  // position in string: -<index value>
  const ranks: number[] = [];
  let score = 0;
  membership.members.forEach(m => {
    const name = m.name.toLocaleLowerCase();
    const parts = name.split(" ");
    const fullWordMatch = parts.indexOf(filterText);
    let result = 0;
    switch(fullWordMatch) {
      case -1:
        // Accumulate matches, so that more matches in names boosts rank
        result = -name.indexOf(filterText);
        break;
      case 0:
        result = -50;
        break;
      case parts.length - 1:
        result = -100;
        break;
      default:
        result = -((fullWordMatch+1) * 2);
        break;
    }
      ranks.push(result);
      score += result;
  })

  //console.log(membership.membershipId + " ranks: " + filterText + ": " + ranks)
  //return Math.min(...ranks);
  return score;
};

const filter = (items: IMembershipTracker[], filterStr: string) => {
  if (!filterStr) {
    return [];
  }
  const f = filterStr.toLowerCase();
  //console.log("filtering: " + f);
  let newItems: [IMembershipTracker, number][] = [];
  items.forEach((item) => {
    if (
      item.members.some(
        (member) => !member.isGuest && member.name.toLowerCase().includes(f)
      )
    ) {
      const rank = rankList(f, item);
      newItems.push([item, rank]);
    }
  });

  newItems.sort((a:[IMembershipTracker, number] ,b: [IMembershipTracker, number]) => {
    return a[1] - b[1];
  })
  return newItems.map(item => item[0]);
};

interface IProps {
  onCheckInUser: (u: IMember[]) => void;
  onCheckOutUser: (u: IMember[]) => void;
}

const renderFilterSelection = (text: string, filter: string) : ReactElement => {
  const lowerCase = text.toLocaleLowerCase();
  const filterLower = filter.toLocaleLowerCase();
  // Go through and find the ranges
  const strings = [];
  let i = lowerCase.indexOf(filterLower);
  let cur = 0;
  while (i !== -1) {
    strings.push(text.substring(cur, i));
    cur = i + filterLower.length;
    strings.push(text.substring(i, cur));
    i = lowerCase.indexOf(filterLower, cur);
  }
  strings.push(text.substring(cur));

  let strong = true;
  return (<>
    {strings.map(span => {
      strong = !strong;
      if (strong) {
        return (<strong>{span}</strong>);
      } else {
        return (<>{span}</>);
      }
    })}
    </>)
};

export const MemberList = ({ onCheckInUser, onCheckOutUser }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { members, setMembers } = useContext(MembershipList);
  const [items, setItems] = useState<IMembershipTracker[]>(members);
  const [filterStr, setFilterStr] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedMembership, setSelectedMembership] =
    useState<IMembershipTracker | null>(null);

  const renderMembers = (m: IMember) => (
    <Stack
      // styles={rootStyle(index)}
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      // onClick={() => alert(m.membershipId)}
    >
      <Text styles={rootStyle(0)} variant="large">
      {m.isCheckedIn && <span style={{color: "#030099"}}><Icon iconName="SkypeCircleCheck"/></span>} {renderFilterSelection(m!.name, filterStr)}
      </Text>
    </Stack>
  );

  const renderMembership = (item?: IMembershipTracker, index?: number) => {
    // const allUsersCheckedIn: boolean = item!.members.every(
    //   (m) => m.isCheckedIn
    // );
    // const anyUsersCheckedIn: boolean = item!.members.some((m) => m.isCheckedIn);
    // const key: string = item!.name;

    return (
      <Stack
        styles={{
          root: {
            marginTop: 20,
            padding: 5,
            border:
              item === selectedMembership
                ? "4px solid #030099"
                : "1px dashed black",
            borderRadius: 25,
          },
        }}
        onClick={() => {
          setSelectedMembership(item || null);
          setShowModal(true);
        }}
      >
        {item!.members.map((m) => renderMembers(m))}
        <Text variant="small">{item?.guestPasses} {item!.name.includes("Punch Card") ? "punch pass" : "guest pass"}{item?.guestPasses !== 1 ? "es" : ""} available</Text>
      </Stack>
    );
  };

  const rootStyle = (index: number) => ({
    root: {
      margin: 2,
      // marginTop: 10,
      // marginBottom: 10,
      padding: 2,
      // background: index % 2 === 0 ? "#030099" : "#fefefe",
      background: "#fefefe",
      // border: "solid 2px #030099",
      // borderRadius: 25,
      width: 350,
      color: "black",
    },
  });

  useEffect(() => {
    setItems(filter(members, filterStr));
  }, [filterStr, members, selectedMembership]);

  return (
    <div data-is-scrollable="true">
      <Stack tokens={{ childrenGap: 30 }}>
        <SearchBox
          placeholder="Enter a name"
          onChange={(evt, newValue) => setFilterStr(newValue || "")}
          onSearch={(newValue) => setFilterStr(newValue)}
        />
        <List items={items} onRenderCell={renderMembership}></List>
        {filterStr.length > 0 && filter(members, filterStr).length === 0 && (
          <Text>
            Not listed and expect to be? Fill out a report{" "}
            <Link href="/?membernotfound">here</Link> and our membership
            coordiantor will help figure things out.
          </Text>
        )}
        <CheckinModal
          isOpen={showModal}
          onDismiss={() => {
            setShowModal(false);
            setSelectedMembership(null);
          }}
          onCheckInUser={onCheckInUser}
          onCheckOutUser={onCheckOutUser}
          membership={selectedMembership}
        ></CheckinModal>
      </Stack>
    </div>
  );
};
