// TODO: may need to check guest counts correctly. I.e. we should only be deducting from punch card
//       any 'new' guests since last update.

import { useState } from "react";
import {
  PrimaryButton,
  Stack,
  Text,
  Icon,
  DefaultButton,
  Checkbox,
  IconButton,
} from "@fluentui/react";
import { IMember, IMembershipTracker } from "./Membership";
import "./MemberCheckIn.css";

// const filter = (items: IMembershipTracker[], filterStr: string) => {
//   if (!filterStr) {
//     return [];
//   }
//   const f = filterStr.toLowerCase();
//   console.log("filtering: " + f);
//   let newItems: IMembershipTracker[] = [];
//   items.forEach((item) => {
//     if (item.members.some((member) => member.name.toLowerCase().includes(f))) {
//       newItems.push(item);
//     }
//   });
//   return newItems;
// };

const rootStyle = (index: number) => ({
  root: {
    margin: 5,
    // marginTop: 10,
    // marginBottom: 10,
    padding: 11,
    // background: index % 2 === 0 ? "#030099" : "#fefefe",
    background: "#fefefe",
    // border: "solid 2px #030099",
    // borderRadius: 25,
    width: 350,
    color: "black",
  },
});

const buttonStyle = (disable: boolean) => ({
  root: {
    background: disable ? "#F0F0F0" : "#030099",
    //    height: 45,
    width: 140,
    margin: 12,
    borderRadius: 12,
  },
});

const buttonStyleAll = (disable: boolean) => {
  const style = buttonStyle(disable);
  //  style.root.width += 30;
  return style;
};

interface IProps {
  members: IMembershipTracker[];
  onQueueCheckInUser: (u: IMember[], isAdd: boolean) => void;
  onQueueCheckOutUser: (u: IMember[], isAdd: boolean) => void;
  onAddGuest: (m: IMember) => void;
  onRemoveGuest: (m: IMember) => void;
}

interface ICheckInDialogData {
  name: string;
  checkInState: boolean;
  checkOutState: boolean;
  member: IMember;
  isAdded?: boolean;
}

const MemberCheckIn2 = ({
  members,
  onQueueCheckInUser,
  onQueueCheckOutUser,
  onAddGuest,
  onRemoveGuest,
}: IProps) => {
  const [guestCount, setGuestCount] = useState<number>((): number => {
    let count = 0;
    members[0].members.forEach((m) => {
      if (m.isGuest && !m.isCheckedIn) {
        count += 1;
      }
    });
    return count;
  });

  const [dialogData, setDialogData] = useState<ICheckInDialogData[]>(
    members[0].members.map((m) => ({
      name: m.name,
      checkInState: m.isCheckedIn,
      checkOutState: !m.isCheckedIn,
      member: m,
      isAdded: m.isGuest && !m.isCheckedIn, // TODO: && m.hasn't checked in
    }))
  );

  const updateState = (
    name: string,
    newCheckInState: boolean,
    newCheckOutState: boolean
  ) => {
    const newList: ICheckInDialogData[] = [];
    dialogData.forEach((m) => {
      if (m.name === name) {
        newList.push({
          ...m,
          checkInState: newCheckInState,
          checkOutState: newCheckOutState,
        });
      } else {
        newList.push(m);
      }
    });
    setDialogData(newList);
  };

  const removeAddedGuest = (guestMember: ICheckInDialogData) => {
    setDialogData(
      dialogData.filter((elm) => elm.name !== guestMember.name, dialogData)
    );
    onRemoveGuest({ name: guestMember.name, isGuest: true } as IMember);
    setGuestCount(guestCount - 1);
  };

  const generatePunchCardRendering = (
    mb: IMembershipTracker,
    guests: number
  ) => {
    const punches = [];
    const available = mb.guestPasses - 1;
    const used = guests + 1;

    for (let i = 0; i < available - used + 1; i++) {
      punches.push(<Icon iconName="CircleFill" />);
    }
    for (let i = 0; i < used; i++) {
      punches.push(<Icon style={{ color: "maroon" }} iconName="CircleFill" />);
    }
    for (let i = available + 1; i < 10; i++) {
      punches.push(<Icon iconName="CircleRing" />);
    }

    // for (var i = 0; i < usedBySelf + usedByGuests; i++) {
    //   punches.push(<Icon style={{ color: "grey" }} iconName="CircleFill" />);
    // }

    return (
      <Stack>
        <Stack horizontal tokens={{ childrenGap: 2 }} style={{ margin: 10 }}>
          {punches}
        </Stack>
        <Stack.Item align="end">
          <Text variant="tiny">
            {"Will consume "}
            {guests + 1}
            {" punches."}
          </Text>
        </Stack.Item>
      </Stack>
    );
  };

  const renderMembers = (m: ICheckInDialogData, index: number) => (
    <Stack
      // styles={rootStyle(index)}
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
    >
      <Text styles={rootStyle(index)} variant="large">
        {m!.name}
        {m!.isAdded && (
          <IconButton
            style={{ color: "red" }}
            iconProps={{ iconName: "RemoveFilter" }}
            onClick={() => removeAddedGuest(m!)}
          ></IconButton>
        )}
      </Text>

      <div style={{ width: 140 }}>
        {!m.member.isCheckedIn && (
          <Checkbox
            label="Check In"
            checked={m.checkInState}
            onChange={(evt, checked) => {
              console.log("Foo");
              updateState(m.name, checked!, m.checkOutState);
              onQueueCheckInUser([m.member], checked!);
            }}
          />
        )}
      </div>
      <div style={{ width: 140 }}>
        {m.member.isCheckedIn && (
          <Checkbox
            label="Check Out"
            defaultChecked={false}
            checked={m.checkOutState}
            onChange={(evt, checked) => {
              updateState(m.name, m.checkInState, checked!);
              onQueueCheckOutUser([m.member], checked!);
            }}
          />
        )}
      </div>
    </Stack>
  );

  const renderMembership = (
    item?: IMembershipTracker,
    dialogData?: ICheckInDialogData[],
    index?: number
  ) => {
    const allUsersCheckedIn: boolean = item!.members.every(
      (m) => m.isCheckedIn
    );
    const anyUsersCheckedIn: boolean = item!.members.some((m) => m.isCheckedIn);

    return (
      <Stack
        styles={{
          root: {
            margin: 8,
            padding: 12,
            border: "1px solid #030099",
            borderRadius: 12,
          },
        }}
        tokens={{ childrenGap: 5 }}
      >
        <Stack.Item align="end">
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            {/* <Text
            styles={{ root: { width: 350, fontWedght: "semiBold" } }}
            variant="large"
          >
            {item!.members.length > 1 ? item!.name : " "}
          </Text> */}
            {dialogData!.length > 1 && (
              <PrimaryButton
                styles={buttonStyleAll(allUsersCheckedIn)}
                disabled={allUsersCheckedIn}
                onClick={() => {
                  setDialogData(
                    dialogData!.map((m) => ({
                      ...m,
                      checkInState: true,
                    }))
                  );

                  onQueueCheckInUser(item!.members, true);
                }}
              >
                Check In All
              </PrimaryButton>
            )}
            {dialogData!.length > 1 && (
              <PrimaryButton
                styles={buttonStyleAll(!anyUsersCheckedIn)}
                disabled={!anyUsersCheckedIn}
                onClick={() => {
                  setDialogData(
                    dialogData!.map((m) => ({
                      ...m,
                      checkOutState: true,
                    }))
                  );

                  onQueueCheckOutUser(item!.members, true);
                }}
              >
                Check Out All
              </PrimaryButton>
            )}
          </Stack>
        </Stack.Item>
        <Stack>{dialogData!.map((m) => renderMembers(m, index || 0))}</Stack>
        <Stack.Item align="end">
          <Stack horizontal>
            {item!.name === "10 Visit Punch Card" &&
              !item?.members[0].isCheckedIn &&
              generatePunchCardRendering(item!, guestCount)}
            {/* <Guests guestPassesAvailable={item!.guestPasses} membership={members[0]}></Guests> */}
            {/* {!allUsersCheckedIn && (
          <Guests guestPassesAvailable={item!.guestPasses} />
        )} */}
          </Stack>
        </Stack.Item>
      </Stack>
    );
  };
  //   useEffect(() => {
  //     if (filterStr) {
  //       setItems(filter(members, filterStr));
  //     }
  //   }, [members, filterStr]);

  return (
      <Stack>
        {renderMembership(members[0], dialogData)}
        <Stack.Item align="end">
          <DefaultButton
            style={{ maxWidth: 120, margin: 8, borderRadius: 12 }}
            onClick={() => {
              // Make sure we can add a guest. For all non punch card memberships we allow them to go negative
              // but for punch cards we do not.
              if (
                members[0].name === "10 Visit Punch Card" &&
                members[0].guestPasses < guestCount + 1 + 1 // guests + self + new guest
              ) {
                // TODO: error!
                console.log("Error with punch card limit.");
                return;
              }

              let nameCounter = 0;
              members[0].members.forEach((m) => {
                if (m.isGuest) {
                  nameCounter += 1;
                  const lastGuestNum = parseInt(m.name.split(" ")[1]);
                  if (lastGuestNum > nameCounter) {
                    nameCounter = lastGuestNum;
                  }
                }});

              const guestMember = {
                name: "Guest " + (nameCounter + 1),
                checkInState: true,
                checkOutState: false,
                member: {
                  name: "Guest " + (nameCounter + 1),
                  isGuest: true,
                  membershipId: members[0].membershipId,
                } as IMember,
                isAdded: true,
              };

              setDialogData([...dialogData, guestMember]);
              onAddGuest({
                name: guestMember.name,
                membershipId: members[0].membershipId,
                isGuest: true,
              } as IMember);
              setGuestCount(guestCount + 1);
            }}
          >
            Add Guests
          </DefaultButton>
        </Stack.Item>
        {guestCount > 0 && guestCount > members[0].guestPasses && (
          <>
            <Text variant="medium" style={{ color: "red" }}>
              Warning! Using more passes ({guestCount}) than available (
              {members[0].guestPasses}). Warn member they will need to purchase
              more.
            </Text>
          </>
        )}
      </Stack>
  );
};

export default MemberCheckIn2;
